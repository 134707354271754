import { Box } from "@mui/material";
import * as React from 'react';
import ServicesCard from "./ServicesCard";

function Services() {

    const services = [
        { title: 'Wild Room Splatter Sessions', price: '$25+', description: 'Each session includes: ' },
        // { title: 'Wild Room - Basic Splatter Session', price: '$30', description: 'A splatter session includes...' },
        // { title: 'Wild Room - Premium Splatter Session', price: '$35', description: 'A splatter session includes...' },
        // { title: 'Wild Room - VIP Splatter Session', price: '$40', description: 'A splatter session includes...' },
        { title: 'Splatter Space Sessions', price: '$20+', description: 'A splatter session includes...' },
        // { title: 'Splatter Space - Basic', price: '$25', description: 'A splatter session includes...' },
        // { title: 'Splatter Space - Premium', price: '$30', description: 'A splatter session includes...' },
        // { title: 'Splatter Space - VIP', price: '$35', description: 'A splatter session includes...' },
        { title: 'Splatter Session Add-Ons', price: '$10+', description: 'Paint pouring is a fun and easy...' },
        { title: 'Private Parties - Wild Room', price: '$350+', description: 'Paint pouring is a fun and easy...' },
        { title: 'Private Parties - Splatter Space', price: '$325+', description: 'Paint pouring is a fun and easy...' },
        { title: 'Party Add-Ons', price: '$2+', description: 'Paint pouring is a fun and easy...' },
    ];

    const servicesParentBoxStyle = {
        display: 'flex',
        flexWrap: 'wrap',
        width: '90%',
        maxWidth: '90%',
        justifyContent: 'center',
        paddingTop: '6%',
        minHeight: '75vh',
        gap: '2%',
        paddingLeft: '5%',
        paddingRight: '5%',
    }

    return (
        <Box sx={servicesParentBoxStyle}>
            {services.map((service, index) => {
                return (
                    <Box key={index} paddingBottom={'3%'}>
                    <ServicesCard
                        title={service.title}
                        price={service.price}
                        description={service.description}   
                    />
                    </Box>
                )
            })}
        </Box>
    )
}

export default Services;