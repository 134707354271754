
//             <h1 style={headerStyle}>FAQS</h1>
//             <p style={{font: 'Lato'}}>Here you can find answers to our most frequently asked questions! If you still can't find an answer, please contact us directly. </p>
//             <Accordion sx={accordionStyle}>
//                 <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
//                     <Typography  sx={accordionHeaderStyle}>WHAT IS WILD AT ART?</Typography>
//                 </AccordionSummary>
//                 <AccordionDetails>
//                     <Typography sx={accordianDetailsStyle}>
//                         Wild At Art is a creative studio where everyone is an artist and can let their creativity run wild! We offer a variety of paint classes by local artists, crafts, slime making, texture painting, paint pouring, and our most popular activity - splatter sessions, for all ages and skill levels. We also host private events and parties.
//                     </Typography>
//                 </AccordionDetails>
//             </Accordion>
//             <Accordion sx={accordionStyle}>
//                     <AccordionSummary>
//                         <Typography  sx={accordionHeaderStyle}>What services do you offer?</Typography>
//                     </AccordionSummary>
//                     <AccordionDetails>
//                         <Typography sx={accordianDetailsStyle}>
//                             Wild At Art is a creative studio where everyone is an artist and can let their creativity run wild! We offer a variety of paint classes by local artists, crafts, slime making, texture painting, paint pouring, and our most popular activity - splatter sessions, for all ages and skill levels. We also host private events and parties.
//                         </Typography>
//                     </AccordionDetails>
//             </Accordion>
//             <Accordion sx={accordionStyle}>
//                     <AccordionSummary>
//                         <Typography  sx={accordionHeaderStyle}>What is a splatter session?</Typography>
//                     </AccordionSummary>
//                     <AccordionDetails>
//                         <Typography sx={accordianDetailsStyle}>
//                             Wild At Art is a creative studio where everyone is an artist and can let their creativity run wild! We offer a variety of paint classes by local artists, crafts, slime making, texture painting, paint pouring, and our most popular activity - splatter sessions, for all ages and skill levels. We also host private events and parties.
//                         </Typography>
//                     </AccordionDetails>
//             </Accordion>
//             <Accordion sx={accordionStyle}>
//                     <AccordionSummary>
//                         <Typography  sx={accordionHeaderStyle}>Do you host parties & private events?</Typography>
//                     </AccordionSummary>
//                     <AccordionDetails>
//                         <Typography sx={accordianDetailsStyle}>
//                             Wild At Art is a creative studio where everyone is an artist and can let their creativity run wild! We offer a variety of paint classes by local artists, crafts, slime making, texture painting, paint pouring, and our most popular activity - splatter sessions, for all ages and skill levels. We also host private events and parties.
//                         </Typography>
//                     </AccordionDetails>
//             </Accordion>
//             <Accordion sx={accordionStyle}>
//                     <AccordionSummary>
//                         <Typography  sx={accordionHeaderStyle}>How many people can Wild at Art accomodate?</Typography>
//                     </AccordionSummary>
//                     <AccordionDetails>
//                         <Typography sx={accordianDetailsStyle}>
//                             Wild At Art is a creative studio where everyone is an artist and can let their creativity run wild! We offer a variety of paint classes by local artists, crafts, slime making, texture painting, paint pouring, and our most popular activity - splatter sessions, for all ages and skill levels. We also host private events and parties.
//                         </Typography>
//                     </AccordionDetails>
//             </Accordion>
//             <Accordion sx={accordionStyle}>
//                     <AccordionSummary>
//                         <Typography  sx={accordionHeaderStyle}>What types of painting do you offer?</Typography>
//                     </AccordionSummary>
//                     <AccordionDetails>
//                         <Typography sx={accordianDetailsStyle}>
//                             Wild At Art is a creative studio where everyone is an artist and can let their creativity run wild! We offer a variety of paint classes by local artists, crafts, slime making, texture painting, paint pouring, and our most popular activity - splatter sessions, for all ages and skill levels. We also host private events and parties.
//                         </Typography>
//                     </AccordionDetails>
//             </Accordion>
//             <Accordion sx={accordionStyle}>
//                     <AccordionSummary>
//                         <Typography  sx={accordionHeaderStyle}>What types of crafts do you offer?</Typography>
//                     </AccordionSummary>
//                     <AccordionDetails>
//                         <Typography sx={accordianDetailsStyle}>
//                             Wild At Art is a creative studio where everyone is an artist and can let their creativity run wild! We offer a variety of paint classes by local artists, crafts, slime making, texture painting, paint pouring, and our most popular activity - splatter sessions, for all ages and skill levels. We also host private events and parties.
//                         </Typography>
//                     </AccordionDetails>
//             </Accordion>
//             <Accordion sx={accordionStyle}>
//                     <AccordionSummary>
//                         <Typography  sx={accordionHeaderStyle}>What are your hours?</Typography>
//                     </AccordionSummary>
//                     <AccordionDetails>
//                         <Typography sx={accordianDetailsStyle}>
//                             Wild At Art is a creative studio where everyone is an artist and can let their creativity run wild! We offer a variety of paint classes by local artists, crafts, slime making, texture painting, paint pouring, and our most popular activity - splatter sessions, for all ages and skill levels. We also host private events and parties.
//                         </Typography>
//                     </AccordionDetails>


import React from 'react';
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function FAQ() {
    const questions = [
        { question: 'What is Lorem ipsum?', answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.' },
        { question: 'Why do we use it?', answer: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.' },
        { question: 'Where does it come from?', answer: 'Contrary to popular belief, Lorem Ipsum is not simply random text.' },
    ];

    const headerStyle = {
        fontFamily: 'Oswald',
        fontWeight: '500',
    };

    const iconStyle = {
        color: '#000', // Adjust the color of the dropdown icon as needed
        cursor: 'pointer',
    };

    const accordionStyle = {
        backgroundColor: '#DFABED',
        borderRadius: '4px',
        marginBottom: '10px',
        minHeight: ['2px', '30px', '60px'],
    };

    return (
        <Container sx={{ textAlign: 'center' }}>
            <h1 style={headerStyle}>FAQS</h1>
            <p>Here you can find answers to our most frequently asked questions! If you still can't find an answer, please contact us. </p>
            <br/>
            {questions.map((item, index) => (
                <Accordion key={index} sx={accordionStyle}>
                    <AccordionSummary expandIcon={<ArrowDropDownIcon style={iconStyle} />}>
                        <Typography variant="h6" style={{ fontFamily: 'Oswald', fontWeight: '300' }}>
                            {item.question}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>{item.answer}</Typography>
                    </AccordionDetails>
                </Accordion>
            ))}
        </Container>
    );
}

export default FAQ;