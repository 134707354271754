import { Navigate } from 'react-router-dom';
import React from 'react';
import About from './components/About';
import Services from './components/Services';
import Faqs from './components/FAQ';
import Home from './components/Home';


const routes = [
    {
        path: '/',
        component: <Home />,
    },
    {
        path: '/services',
        component: <Services />,
    },
    // {
    //     path: '/about',
    //     component: <About />,
    // },
    {
        path: '/faqs',
        component: <Faqs />,
    }
]

export default routes;