import './App.css';
import React, { AppContent } from 'react';
import { firstApiCall } from './api';
import './styling/styling.css';
import routes from './routes';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavBar from './components/NavBar';
import Box from '@mui/material/Box';
import Footer from './components/Footer';

function App() {


  return (
    <Box className='background'>
      <Router>
        <NavBar />
        <Box component="main" style={{ flex: 1 }}>
          <Routes>
            {routes.map((route, index) => (
              <Route key={index} path={route.path} element={route.component} />
            ))}
          </Routes>
        </Box>
        <Footer />
      </Router>
    </Box>
  );
}


export default App;
