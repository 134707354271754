import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import '../styling/styling.css';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

function ServicesCard(props) {
    const { title, price, description } = props;
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className='servicesCard' sx={{  backgroundColor: 'rgba(255, 255, 255, 0.5)'}}>
      <CardHeader
              title={
                  <Typography  variant="h5" style={{ fontFamily: 'Oswald', fontSize: '1.2rem' }}>
                      {title}
                  </Typography>
              }
              subheader={
                  <Typography variant="h7" style={{ fontFamily: 'Oswald',  fontSize: '1.0rem'  }}>
                      {price}
                  </Typography>
              }
      />
      <CardMedia
        component="img"
        image="/static/images/cards/paella.jpg"
        alt="Image Here"
        className='cardMedia'
      />
      <CardActions disableSpacing>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>
            {description}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}

export default ServicesCard;