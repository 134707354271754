import React from 'react';
import { Link } from 'react-router-dom';
import '../styling/navbarstyles.css'
import Logo from '../images/TransparentLogo.png';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';

const NavBar = () => {
    const navBoxStyle = {
        display: 'flex',
        justifyContent: 'flex-start', 
        paddingRight: [0, 0, 8],
    }


    const navButtonStyle = {
        color: "black",
        fontSize: ['0.7rem', '0.7rem', '1.15rem'],
        fontFamily: "futura",
        color: "black",
        marginX: [0, 1, 5], 

    }

    const appBarStyle = {
        background: 'transparent',
        boxShadow: 'none',
        marginBottom: 0,
        height: '180px', // Default height for larger screens
    };

    const mediaQueryStyle = {
        height: '95px', // Adjust height for iPhones
    };

    return (
        <AppBar position="static" sx={{ ...appBarStyle, '@media (max-width: 768px)': mediaQueryStyle }}>
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <img src={Logo} className='logo'/>
                    <Box sx={navBoxStyle}>
                        <Button component={Link} to="/" color="inherit" sx={navButtonStyle}>
                            HOME
                        </Button>
                        <Button component={Link} to="/about" color="inherit"  sx={navButtonStyle}>
                            ABOUT
                        </Button>
                        <Button component={Link} to="/services" color="inherit"  sx={navButtonStyle}>
                            SERVICES
                        </Button>
                        <Button component={Link} to="/faqs" color="inherit"  sx={navButtonStyle}>
                            FAQ
                        </Button>
                    </Box>
                </Toolbar>
            </AppBar>
    );
  };
  
  export default NavBar;