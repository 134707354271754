import { Box } from "@mui/material";
import '../styling/styling.css';

function Home() {
    const homeCenterBoxStyle = {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'transparent',
        width: '82%',
        borderRadius: '10px',
        paddingLeft: '14%',
        paddingRight: '15%',
        paddingBottom: '0%',
    }
    const homeParentBoxStyle = {
        display: 'flex',
        width: '100%',
        paddingTop: '4%',
        minHeight: '65vh',
    }

    const headerStyle = {
        fontFamily: 'Yeseva One',
        fontWeight: '300',
        fontSize: '3.0rem',
        color: 'black',
    };
    return (
        <Box className='homeParentBox'>
            <Box sx={homeCenterBoxStyle}>
                <h1 className="homeText">Welcome to Wild At Art, where creativity runs wild! </h1>
                <h1 className="homeText">We wanted a place where everyone is an artist - so we made one.</h1>
             </Box>
         </Box>
    )
}

export default Home;