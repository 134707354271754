import React from 'react';
import Logo from '../images/TransparentLogo.png';
import { Container, Typography, Box, Grid } from '@mui/material';

const Footer = () => {
  return (
    <Container component="footer" sx={{ backgroundColor: 'transparent', width: '100%'}}>
      <Typography variant="body2" sx={{ fontSize: 'smaller' }}>
        &copy; 2024 Wild At Art. All rights reserved.
      </Typography>
    </Container>
  );
};

export default Footer;